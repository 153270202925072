import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "@/assets/styles/style.scss";
import getAwesomeSwiper from "@/plugins/vue-awesome-swiper";
import "aos/dist/aos.css";
import AOS from "aos";
Vue.use(getAwesomeSwiper);
Vue.config.productionTip = false;
AOS.init({
  once: true,
});
new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
