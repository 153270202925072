import Vue from "vue";
import Vuex from "vuex";
Vue.use(Vuex);

export const mutationTypes = {
  changeIsShowModal: "mutation/changeIsShowModal",
};
export const actionTypes = {
  getIsShowModal: "action/getIsShowModal",
};

export default new Vuex.Store({
  state: {
    isShowModal: false,
  },
  mutations: {
    [mutationTypes.changeIsShowModal](state, payload) {
      state.isShowModal = payload;
    },
  },
  actions: {
    [actionTypes.getIsShowModal]({ commit }, payload) {
      commit(mutationTypes.changeIsShowModal, payload);
    },
  },
});
